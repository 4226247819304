<div
  class="modal"
  [ngClass]="{ 'show d-block': galleryModalService.modalOpen$ | async }"
  tabindex="-1"
  (click)="galleryModalService.close()"
  role="dialog">
  <div
    class="modal-dialog modal-dialog-centered modal-lg"
    (click)="$event.stopPropagation()">
    <div class="modal-content">
      <button
        type="button"
        class="modal-close btn-close"
        (click)="galleryModalService.close()"
        aria-label="Close"></button>

      <div class="modal-body">
        <img
          [src]="(galleryModalService.selectedImage$ | async)?.link"
          alt="Image"
          class="modal-image img-fluid mx-auto" />

        <button
          *ngIf="galleryModalService.hasPrevImage()"
          class="modal-arrow modal-arrow-prev btn btn-action btn-icon btn-round"
          (click)="galleryModalService.Prev()">
          <i class="ph ph-caret-left"></i>
        </button>
        <button
          *ngIf="galleryModalService.hasNextImage()"
          class="modal-arrow modal-arrow-next btn btn-action btn-icon btn-round"
          (click)="galleryModalService.Next()">
          <i class="ph ph-caret-right"></i>
        </button>
      </div>

      <div
        class="modal-footer d-flex justify-content-center"
        *ngIf="(galleryModalService.imagesCount$ | async)! > 1">
        <div
          *ngFor="let image of galleryModalService.modalImages$ | async; let i = index"
          (click)="galleryModalService.selectImage(i)">
          <img
            [src]="image.link"
            alt="Image"
            class="img-fluid modal-thumb" />
        </div>
      </div>
    </div>
  </div>
</div>
