import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { GalleryModalService } from '../gallery-modal.service';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryModalComponent {
  constructor(public readonly galleryModalService: GalleryModalService) {}

  @HostListener('window:keydown.escape', ['$event'])
  public handleEscKey() {
    if (!this.galleryModalService.isOpen) {
      return;
    }

    this.galleryModalService.close();
  }

  @HostListener('window:keydown.arrowleft', ['$event'])
  public handleLeftKey() {
    if (!this.galleryModalService.isOpen) {
      return;
    }

    this.galleryModalService.Prev();
  }

  @HostListener('window:keydown.arrowright', ['$event'])
  public handleRightKey() {
    if (!this.galleryModalService.isOpen) {
      return;
    }

    this.galleryModalService.Next();
  }
}
