<div class="menu-nav-notification__container">
  @if (isLoggedIn) {
    <button
      class="btn menu-nav-notification d-flex align-items-center"
      (click)="onButtonClick()">
      @if (unreadMessagesCount$ | async; as messageCount) {
        <span class="menu-nav-notification__badge">{{ messageCount }} </span>
      }
      <i class="menu-nav-notification__icon ph-duotone ph-chat-circle-dots me-2"></i>
      <span class="menu-nav-notification-text">
        {{ 'APPLICATION.MENU.NAV.offers' | translate }}
      </span>
    </button>
  }
</div>
