<a
  *ngIf="!isLoggedIn"
  [routerLink]="['/auth/login']"
  >{{ 'APPLICATION.MENU.NAV.login' | translate }}</a
>

<div
  ngbDropdown
  placement="top-end"
  class="menu-nav-profile__container"
  *ngIf="isLoggedIn">
  <button
    type="button"
    class="menu-nav-profile__btn btn d-flex align-items-center w-100"
    id="menuNavProfileDropDown"
    ngbDropdownToggle>
    <i class="menu-nav-profile__icon ph-duotone ph-user me-2"></i>
    <span class="menu-nav-profile__user text-truncate"> {{ userFullName }} </span>
    <i class="menu-nav-profile__icon menu-nav-profile__icon--arrow ph-bold ph-dots-three ms-2"></i>
  </button>
  <div
    class="menu-nav-profile__dropdown-menu"
    ngbDropdownMenu
    aria-labelledby="menuNavProfileDropDown">
    <button
      class="menu-nav-profile__menu-btn"
      ngbDropdownItem
      (click)="onGoToChangePassport()">
      <i class="menu-nav-profile__menu-icon ph-duotone ph-lock-simple"></i> {{ 'APPLICATION.MENU.NAV.changePassword' | translate }}
    </button>
    <div class="dropdown-divider"></div>
    <button
      class="menu-nav-profile__menu-btn"
      ngbDropdownItem
      (click)="onLogout()">
      <i class="menu-nav-profile__menu-icon ph-duotone ph-sign-out"></i> {{ 'APPLICATION.MENU.NAV.logout' | translate }}
    </button>
  </div>
</div>
