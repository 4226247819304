import { Component } from '@angular/core';
import { AuthService } from '../../../../../common/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-menu-nav-profile',
  templateUrl: './menu-nav-profile.component.html',
  styleUrls: ['./menu-nav-profile.component.scss'],
})
export class MenuNavProfileComponent {
  constructor(
    private router: Router,
    private auth: AuthService,
  ) {}

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  get userFullName() {
    return this.auth.getUserFullName();
  }

  public onGoToChangePassport() {
    return this.router.navigate(['user-profile', 'password']);
  }

  public onLogout() {
    this.auth.logout();
    return this.router.navigate(['auth/login']);
  }
}
