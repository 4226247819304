import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitcherComponent } from '../language-switcher/language-switcher.component';
import { SpinnerModule } from '../spinners';
import { IRouteProvider, LayoutConfigToken } from './common/models/layout-config.model';
import { FullLayoutComponent } from './components/full-layout/full-layout.component';
import { MenuNavProfileComponent } from './components/manu-nav-profile/menu-nav-profile.component';
import { MenuNavNotificationComponent } from './components/menu-nav-notification/menu-nav-notification.component';
import { MenuNavComponent } from './components/menu-nav/menu-nav.component';
import { GalleryModalComponent } from './components/modal-gallery/gallery-modal/gallery-modal.component';

@NgModule({
  declarations: [FullLayoutComponent, MenuNavComponent, MenuNavProfileComponent, GalleryModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    TranslateModule,
    SpinnerModule,
    NgbDropdownModule,
    MenuNavNotificationComponent,
    LanguageSwitcherComponent,
  ],
  exports: [FullLayoutComponent, GalleryModalComponent],
})
export class LayoutModule {
  static forRoot(config: Type<IRouteProvider>): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [
        {
          provide: LayoutConfigToken,
          useClass: config,
        },
      ],
    };
  }
}
