import { Component } from '@angular/core';

@Component({
  selector: 'lib-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.scss'],
})
export class MenuNavComponent {
  constructor() {}
}
