import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessagesApiClientModule } from '@as/api';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'projects/agrosell-portal/src/app/common/auth';
import { map, Observable } from 'rxjs';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'lib-menu-nav-notification',
  templateUrl: './menu-nav-notification.component.html',
  styleUrls: ['./menu-nav-notification.component.scss'],
  standalone: true,
  providers: [NotificationService],
  imports: [MessagesApiClientModule, TranslateModule, AsyncPipe],
})
export class MenuNavNotificationComponent {
  private readonly MAX_MESSAGES_COUNT = 9;

  public unreadMessagesCount$: Observable<number | string> = this.notificationService.unreadMessageCountStreamInterval$.pipe(
    map(count => {
      if (!count) {
        return 0;
      }
      return count > this.MAX_MESSAGES_COUNT ? `${this.MAX_MESSAGES_COUNT}+` : count.toString();
    }),
  );

  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
  ) {}

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  protected onButtonClick() {
    this.router.navigate(['offers']);
  }
}
